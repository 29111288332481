import { graphql } from "gatsby"
import React from "react"
import * as styles from "./ContentResidentInfoSection.module.scss"

type ContentResidentInfoSectionProps = Queries.ContentResidentInfoSectionFragment

const ContentResidentInfoSection = ({ residentInfoRefs }: ContentResidentInfoSectionProps) => {
  if (!residentInfoRefs) {
    console.warn("Invalid props for ContentResidentInfoSection")
    return null
  }

  return (
    <section className={styles.container}>
      {residentInfoRefs.map(iref => {
        if (!iref?.title || !iref?.paragraph) return null
        return (
          <div key={iref.id} className={styles.content}>
            <h2>{iref.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: iref.paragraph }} />
          </div>
        )
      })}
    </section>
  )
}

export default ContentResidentInfoSection

export const query = graphql`
  fragment ContentResidentInfoSection on DatoCmsResidentInfoSection {
    residentInfoRefs {
      id
      title
      paragraph
    }
  }
`
